import { buildMediaQueries } from '@rategravity/marketing-components';
import { OwnUpHeader } from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { colors } from '../../modules/colors';

const Label = styled(OwnUpHeader)`
  font-size: 19px;
  background-color: ${colors.MINT};
  padding: 14px;
  max-width: fit-content;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 36px;
  ${buildMediaQueries('xsmall', {
    margin: '0 auto 40px',
    maxWidth: '75%',
    whiteSpace: 'normal'
  })}
`;

export const SectionLabel = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Label variant="box" level={2}>
      <div>{children}</div>
    </Label>
  );
};
