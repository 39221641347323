import { buildMediaQueries } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../modules/colors';

const CountWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexFlow: 'column wrap',
  width: 50,
  variants: {
    mediumAndDown: {
      margin: 'auto'
    }
  }
});

const CountWrapper = createOwnUpComponent(OwnUpBox, CountWrapperStyle);

const CountSpan = styled('span')`
  ${fonts.GRAPHIK_REGULAR};
  font-family: 'Graphik-Regular', 'Arial', 'sans-serif';
  border: 2px solid ${colors.PRIMARY};
  border-radius: 50%;
  display: block;
  font-size: 20px;
  font-weight: 500;
  height: 28px;
  line-height: 28px;
  text-align: center;
  width: 28px;
  ${buildMediaQueries('mediumAndDown', {
    margin: 'auto'
  })}
`;

const DividerStyle = createOwnUpStyle({
  border: `1px solid ${colors.PRIMARY}`,
  marginTop: 25,
  width: 50,
  marginBottom: 35
});

const Divider = createOwnUpComponent('hr', DividerStyle);

export const SectionCount = ({ children }: { children: ReactNode }) => {
  return (
    <CountWrapper>
      <CountSpan>{children}</CountSpan>
      <Divider />
    </CountWrapper>
  );
};
