import loadable from '@loadable/component';
import { ImageQuery, ImageWithContent } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import React from 'react';
import { Section1 } from '../components/about/section-1';
import { Divider } from '../components/divider';
import { IntroImage, Text } from '../components/intro-image';
import { Layout } from '../components/layout';
import { Highlight, ResponsiveSectionHeader } from '../components/typography';
import { founderData } from '../data/content/about';
import GreenDotsBlob2SVG from '../images/green-dots-blob-2.svg';

const AtComputerImage = loadable(() => import('../components/about/section-2'), {
  resolveComponent: (components) => components.AtComputerImage
});

const BinocularsImage = loadable(() => import('../components/about/work-with-us'), {
  resolveComponent: (components) => components.BinocularsImage
});

const DotStrip = loadable(() => import('../components/dot-strip'), {
  resolveComponent: (components) => components.DotStrip
});

const Founders = loadable(() => import('../components/about/founders'), {
  resolveComponent: (components) => components.Founders
});

const Section2 = loadable(() => import('../components/about/section-2'), {
  resolveComponent: (components) => components.Section2
});

const Section3 = loadable(() => import('../components/about/section-3'), {
  resolveComponent: (components) => components.Section3
});

const SmilingHomeownersImage = loadable(() => import('../components/about/section-3'), {
  resolveComponent: (components) => components.SmilingHomeownersImage
});

const WorkWithUs = loadable(() => import('../components/about/work-with-us'), {
  resolveComponent: (components) => components.WorkWithUs
});

export const query = graphql`
  {
    images: allFile(filter: { relativePath: { regex: "/about//" }, extension: { ne: "svg" } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

const AboutIntroContent = () => (
  <>
    <ResponsiveSectionHeader variant="title">
      Welcome to a <br />
      <Highlight>new approach</Highlight> <br />
      to mortgage.
    </ResponsiveSectionHeader>
    <Text>
      In contrast to the eroded consumer trust in financial services &mdash; we are cutting through
      the establishment and connecting with people.
    </Text>
  </>
);

export const About = ({ data }: { data: ImageQuery }) => {
  const AboutIntroImage = (
    <IntroImage imageData={data} imageName="smiling-buyers-blue-shirts.jpg" />
  );

  return (
    <Layout>
      <ImageWithContent
        backgroundImage={GreenDotsBlob2SVG}
        Image={AboutIntroImage}
        label="intro"
        sectionStyle={{ margin: '0 30px' }}
      >
        <AboutIntroContent />
      </ImageWithContent>
      <Divider />
      <Section1 imageData={data} />
      <Divider />
      <Section2>
        <AtComputerImage imageData={data} />
      </Section2>
      <Divider />
      <Section3>
        <SmilingHomeownersImage imageData={data} />
      </Section3>
      <Divider />
      <Founders imageData={data} founderData={founderData} />
      <WorkWithUs>
        <BinocularsImage imageData={data} />
      </WorkWithUs>
      <DotStrip />
    </Layout>
  );
};

export default About;
