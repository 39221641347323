import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import GreenWaveSVG from '../../images/green-wave.svg';

const DividerStyling = createOwnUpStyle({
  backgroundImage: `url(${GreenWaveSVG})`,
  backgroundSize: 'cover',
  height: '6vw',
  marginTop: 30,
  marginBottom: 75,
  marginLeft: 'min(calc((1280px - 100vw) / 2), 0px)',
  marginRight: 'min(calc((1280px - 100vw) / 2), 0px)',
  maxWidth: '100vw',
  variants: {
    mediumAndDown: {
      margin: '0px 0px 30px'
    }
  }
});

export const Divider = createOwnUpComponent(OwnUpBox, DividerStyling);
