import { getImage, ImageQuery } from '@rategravity/marketing-components';
import { buildMediaQueries } from '@rategravity/marketing-components';
import { OwnUpText } from '@rategravity/own-up-component-library';
import styled from 'styled-components';
import { metadata } from '../../data/metadata/images';

export const Text = styled(OwnUpText)`
  display: block;
  font-size: 23px;
  width: 100%;
  margin-bottom: 30px;
  ${buildMediaQueries('mediumAndDown', { textAlign: 'center' })}
  ${buildMediaQueries('mediumAndDown', { fontSize: '16px' })}
`;

export const imgStyle = {
  maxWidth: '580px',
  margin: 'auto',
  position: 'relative' as const
};

export const IntroImage = ({
  imageData,
  imageName,
  eager = true
}: {
  imageData: ImageQuery;
  imageName: string;
  eager?: boolean;
}) => getImage(imageData, imageName, metadata, imgStyle, eager);
