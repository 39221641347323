export const founderData = [
  {
    image: 'patrick-headshot.jpg',
    name: 'Patrick Boyaggi',
    title: 'CO-FOUNDER & CEO',
    catchphrase: 'I’m the guy that didn’t get a mortgage from his own bank.',
    bio: "Patrick forged a successful career as a Senior VP, responsible for Residential Lending at a top-50 national lender — closing $11 billion in residential mortgages. He believes everyone should understand their mortgage and is passionate about Own Up's ability to improve the futures of our customers."
  },
  {
    image: 'mike-headshot.jpg',
    name: 'Mike Tassone',
    title: 'CO-FOUNDER & COO',
    catchphrase:
      'It’s time we cut through the BS and provide people with the transparency they deserve.',
    bio: 'Mike leads the charge for Own Up’s home advisors. Previously, he worked as SVP at a top-50 national lender and co-founded a student lending and financial advisory firm that originated over $4B in student loans. He aims to empower consumers with resources that level the financial playing field.'
  },
  {
    image: 'brent-headshot.jpg',
    name: 'Brent Shields',
    title: 'CO-FOUNDER & CTO',
    catchphrase:
      "I believe borrowers should have total confidence that they're not being ripped off.",
    bio: 'Brent is responsible for Own Up’s pioneering technologies. He has worked as a Software Engineer and Technical Lead in a diverse collection of industries. Brent works vigorously to help remove barriers to understanding mortgages.'
  }
];
