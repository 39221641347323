import { getImage, ImageQuery } from '@rategravity/marketing-components';
import { createOwnUpComponent, createOwnUpStyle } from '@rategravity/own-up-component-library';
import React from 'react';
import { metadata } from '../../../data/metadata/images';
import { NumberedSection, NumberedSectionProps } from '../../numbered-section';
import { SectionLabel } from '../../section-label';

export const section1Data = {
  count: 1,
  header: 'We are former bankers.',
  imageName: 'mike-and-patrick-at-laptop.jpg',
  imagePosition: 'right' as const,
  text: 'Our founders used to be mortgage bankers, yet they didn’t go to their own bank to get a mortgage. Their expertise enabled them to pursue better financial outcomes elsewhere. They built Own Up to allow you to get your mortgage the same way they did.'
};

export const SectionContent = (props: NumberedSectionProps) => (
  <NumberedSection {...props}></NumberedSection>
);

export const imgStyle = {
  maxWidth: 390,
  margin: 'auto',
  position: 'relative' as const,
  borderRadius: '50%'
};

const SectionStyle = createOwnUpStyle({
  margin: '0 30px',
  smallAndDown: {
    margin: '0 10px'
  }
});

const Section = createOwnUpComponent('section', SectionStyle);

export const Section1 = ({ imageData }: { imageData: ImageQuery }) => {
  const Image = getImage(imageData, 'mike-and-patrick-at-laptop.jpg', metadata, imgStyle);
  return (
    <Section>
      <SectionLabel>Our Story</SectionLabel>
      <SectionContent {...section1Data} Image={Image} />
    </Section>
  );
};
